/* TableStyles.module.css */

.header_cell {
    font-weight: bold;
    background-color: #003366;
    color: white !important;
  }
  
  .status_successful {
    color: green;
  }
  
  .status_pending {
    color: orange;
  }
  
  .status_unsuccessful {
    color: red;
  }
  
  .action_button {
    background-color: #F15A24;
    color: white;
    text-transform: none;
    padding: 5px 10px;
    font-size: 0.875rem;
    border-radius: 5px;
  }
  
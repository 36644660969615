.become_pro {
  color: #000 !important;
  font-family: "Raleway" !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110% !important;
}

.done_pro {
  font-family: "Raleway" !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 110% !important;
}

.personal {
  color: #333 !important;
  font-family: "Raleway" !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 110% !important;
}
.details {
  color: black !important;
  font-family: "Raleway" !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 110% !important;
  -webkit-text-fill-color: #000 !important;
}

.steps {
  color: #000 !important;
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 157.49% !important; /* 25.198px */
}

.vertical_line {
  background-color: #949494 !important;
  width: 1px !important;
  height: 40px !important;
}

.next_step {
  color: #949494 !important;
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 157.49% !important;
}

.parent_stepper {
  width: 100%;
  height: 10px;
  background-color: rgba(245, 171, 145, 0.884);
  border-radius: 71px;
  overflow: hidden;
}

.child_stepper {
  height: 10px;
  background: var(--Orange, linear-gradient(180deg, #f15a24 0%, #c53f10 100%));
}

.start_over {
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 157.49% !important;
  cursor: pointer !important;
}

/* Larger screens */
@media (min-width: 1200px) {
  .become_pro,
  .done_pro {
    font-size: 50px !important;
  }
  .personal,
  .details {
    font-size: 28px !important;
  }
  .steps {
    font-size: 14px !important;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .become_pro,
  .done_pro {
    font-size: 40px !important;
  }
  .personal,
  .details {
    font-size: 25px !important;
  }
  .steps {
    font-size: 13px !important;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .become_pro,
  .done_pro {
    font-size: 30px !important;
  }
  .personal,
  .details {
    font-size: 23px !important;
  }
  .steps {
    font-size: 12px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .become_pro,
  .done_pro {
    font-size: 25px !important;
  }
  .personal,
  .details {
    font-size: 21px !important;
  }
  .steps {
    font-size: 11px !important;
  }
}

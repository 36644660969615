.inp {
  outline: none;
  border: none;
  font-size: 13px;
  font-family: "Poppins", sans-serif !important;
  padding: 10px 12px;
  width: 100%;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
  border: 1px solid #f8f9fa;
}

.inp:focus {
  border: 1px solid #f15a24;
}

.title {
  font-size: 17px !important;
  font-weight: 700 !important;
  color: #06194e !important;
}

.banner_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img_wrapper {
  width: 100%;
  height: 70vh;
  background: url("../../assets/contact-banner.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.main_heading {
  color: white !important;
  font-weight: 900 !important;
  line-height: 45px !important;
}

.heading_wrapper {
  border-left: 5px solid #f15a24;
  padding: 0px 20px;
}

.service_man_img {
  background: url("../../assets/service_man_contact_us.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.banner_img_txt {
  font-size: 45px !important;
  color: #f15a24 !important;
  font-weight: 600 !important;
  line-height: 40px !important;
}

.contact_us_btn {
  outline: none !important;
  border: 2px solid white;
  padding: 12px 25px;
  color: white;
  background-color: transparent !important;
  transition: all 0.3s ease !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content !important;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.contact_us_btn:hover {
  background-color: white !important;
  color: #f15a24;
}

/* Larger screens */
@media (min-width: 1200px) {
  .contact_us_btn {
    font-size: 15px !important;
  }
  .banner_img_txt {
    font-size: 45px !important;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .contact_us_btn {
    font-size: 13px !important;
  }
  .banner_img_txt {
    font-size: 43px !important;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .contact_us_btn {
    font-size: 13px !important;
  }
  .banner_img_txt {
    font-size: 40px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .contact_us_btn {
    font-size: 11px !important;
  }
  .banner_img_txt {
    font-size: 37px !important;
  }
}

.inp_label {
  font-size: 14px !important;
  color: #565774 !important;
  font-family: "Poppins", sans-serif !important;
}

.email_location {
  font-size: 14px !important;
  color: #3549de !important;
}

.intro_title {
  color: black !important;
  font-weight: 700 !important;
  line-height: 45px !important;
}

.intro_description {
  color: black !important;
  font-weight: 600 !important;
  line-height: 30px !important;
}

.css-eudt4b-MuiPaper-root-MuiAccordion-root:before {
  content: "";
}

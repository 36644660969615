.business_profile_txt {
  font-family: var(--font_raleway) !important;
  color: black !important;
  font-weight: 500 !important;
}
.business_profile_descr {
  font-family: var(--font_raleway) !important;
  color: grey !important;
  font-weight: 500 !important;
}

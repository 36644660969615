.vendor_top_label_bl {
  color: #000 !important;
  font-family: "Raleway" !important;
  font-size: 50px !important;
  font-weight: 500 !important;
  line-height: 110% !important;
  letter-spacing: -2px !important;
  -webkit-text-fill-color: black;
}
.main_heading {
  font-family: "Raleway" !important;
  color: #333 !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: 41px !important;
}

.total {
  color: #333 !important;
  font-family: "Raleway" !important;
  font-size: 30px !important;
  font-weight: 500 !important;
  line-height: 41px !important;
}
.vendor_top_label_or {
  color: #f15a24 !important;
  font-family: "Raleway" !important;
  font-size: 50px !important;
  font-weight: 500 !important;
  line-height: 110% !important;
  letter-spacing: -2px !important;
}

.avail_jobs {
  border-radius: 22px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15) !important;
  position: relative !important;
}

.avail_count {
  color: #fff !important;
  font-family: "Raleway" !important;
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.avail_txt {
  font-family: "Raleway" !important;
  font-size: 30px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.avail_job_txt,
.earned_title {
  /* color: var(--Orange, #f15a24) !important; */
  font-family: "Raleway" !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
.earned_title {
  color: var(--Orange, #f15a24) !important;
}

.earned_count {
  background: var(
    --Blue-colour,
    linear-gradient(180deg, #071d5b 0%, #031444 100%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;

  font-family: var(--font_rokkitt) !important;
  font-size: 50px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.dollar {
  font-size: 22px !important;
}

/* Larger screens */
@media (min-width: 1200px) {
  .vendor_top_label_or,
  .vendor_top_label_bl,
  .earned_count {
    font-size: 50px !important;
  }
  .main_heading,
  .total,
  .avail_count,
  .avail_txt,
  .avail_job_txt,
  .earned_title {
    font-size: 30px !important;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .vendor_top_label_or,
  .vendor_top_label_bl,
  .earned_count {
    font-size: 48px !important;
  }
  .main_heading,
  .total,
  .avail_count,
  .avail_txt,
  .avail_job_txt,
  .earned_title {
    font-size: 28px !important;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .vendor_top_label_or,
  .vendor_top_label_bl,
  .earned_count {
    font-size: 45px !important;
  }
  .main_heading,
  .total,
  .avail_count,
  .avail_txt,
  .avail_job_txt,
  .earned_title {
    font-size: 26px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .vendor_top_label_or,
  .vendor_top_label_bl,
  .earned_count {
    font-size: 42px !important;
  }
  .main_heading,
  .total,
  .avail_count,
  .avail_txt,
  .avail_job_txt,
  .earned_title {
    font-size: 24px !important;
  }
}

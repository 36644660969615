.total_revenue_wrapper {
  border-radius: 32px;
  border: 1.628px solid #f8f9fa;

  background: var(--White, #fff);
  box-shadow: 0px 6.512px 32.558px 0px #eeeeee80;

  padding: 42px 40px;
}

.total_rev {
  color: #333 !important;
  font-family: Helvetica !important;
  font-size: 32.558px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 52.093px !important;
}

.first_heading_bl {
  color: #000 !important;
  font-family: "Raleway" !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: 140% !important;
  letter-spacing: -0.142px !important;
}
.first_heading_or {
  font-family: "Raleway" !important;
  background: var(
    --Orange,
    linear-gradient(180deg, #f15a24 0%, #c53f10 100%)
  ) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: 140% !important;
  letter-spacing: -0.142px !important;
}

.job_accepted_description {
  color: #333 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}

.job_accepted_banner {
  background: url(../../assets//accepted_job_baner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 45vh;
  width: 40vw !important;
  position: relative;
}

.job_accepted_content_wrapper {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  width: 62%;
  height: 63%;
  position: absolute;
  right: 16%;
  top: 21%;
  padding: 23px 35px !important;
}

.job_accepted_title {
  color: #fff !important;
  font-family: "Raleway" !important;
  font-size: 35px !important;
  font-weight: 700 !important;
  line-height: 40px !important;
  letter-spacing: -0.142px !important;
  text-decoration-line: underline !important;
  text-align: center !important;
}

.job_accepted_name {
  color: #fff !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}
.job_accepted_user_detail {
  color: #fff !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 25px !important;
}
.user_profile_img_wrapper {
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #fff;
}
.user_profile_img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.job_accepted_user_name {
  color: #fff !important;
  font-family: "Raleway" !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 25px !important; /* 136.364% */
}

.job_accepted_certification {
  color: #fff !important;
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 30px !important; /* 187.5% */
}

/* Larger screens */
@media (min-width: 1200px) {
  .first_heading_bl,
  .first_heading_or {
    font-size: 30px !important;
  }
  .job_accepted_title {
    font-size: 34px !important;
  }
  .job_accepted_description,
  .job_accepted_certification {
    font-size: 15px !important;
  }
  .job_accepted_name,
  .job_accepted_user_detail {
    font-size: 17px !important;
  }
  .job_accepted_user_name {
    font-size: 15px !important;
  }
  .job_accepted_banner {
    height: 45vh;
    width: 40vw !important;
  }
  .job_accepted_content_wrapper {
    width: 62%;
    height: 70%;
    top: 17.5%;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .first_heading_bl,
  .first_heading_or {
    font-size: 28px !important;
  }
  .job_accepted_title {
    font-size: 30px !important;
  }
  .job_accepted_description,
  .job_accepted_certification {
    font-size: 14px !important;
  }
  .job_accepted_name,
  .job_accepted_user_detail {
    font-size: 15px !important;
  }
  .job_accepted_user_name {
    font-size: 13px !important;
  }
  .job_accepted_banner {
    height: 45vh;
    width: 40vw !important;
  }
  .job_accepted_banner {
    height: 45vh;
    width: 47vw !important;
  }
  .job_accepted_content_wrapper {
    width: 67%;
    height: 67%;
    position: absolute;
    right: 13%;
    top: 19%;
  }
}
@media (max-width: 900px) and (min-width: 600px) {
  .job_accepted_banner {
    height: 45vh;
    width: 100% !important;
  }
  .job_accepted_content_wrapper {
    width: 54%;
    height: 71%;
    position: absolute;
    right: 20.5%;
    top: 18%;
  }
}
@media (max-width: 600px) {
  .job_accepted_banner {
    height: 45vh;
    width: 100% !important;
    background-size: 100% 100%;
  }
  .job_accepted_content_wrapper {
    width: 75%;
    height: 73%;
    position: absolute;
    right: 9%;
    top: 16%;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .first_heading_bl,
  .first_heading_or {
    font-size: 26px !important;
  }
  .job_accepted_title {
    font-size: 28px !important;
  }
  .job_accepted_description,
  .job_accepted_certification {
    font-size: 14px !important;
  }
  .job_accepted_name,
  .job_accepted_user_detail {
    font-size: 14px !important;
  }
  .job_accepted_user_name {
    font-size: 12px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .first_heading_bl,
  .first_heading_or {
    font-size: 24px !important;
  }
  .job_accepted_title {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  .job_accepted_description {
    font-size: 12px !important;
  }

  .job_accepted_certification {
    font-size: 10px !important;
    line-height: 18px !important;
  }
  .job_accepted_name,
  .job_accepted_user_detail {
    font-size: 13px !important;
    line-height: 20px !important;
  }
  .job_accepted_user_name {
    font-size: 10px !important;
  }
}

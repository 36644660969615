/* .img_wrapper {
  width: 100vw;
  height: 160vh;
  background: url("../../assets/coming_soon_app.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main_heading {
  color: white !important;
  font-weight: 900 !important;
  line-height: 45px !important;
} */

.img_wrapper {
  width: 100%;
  height: 110vh; /* Changed to make it fit the viewport height */
  background: url("../../assets/coming_soon_app.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto; /* Ensures the image covers the entire area */
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media (min-width: 1300px) {
  .img_wrapper {
    height: 160vh;
  }
}

@media (max-width: 1200px) {
  .img_wrapper {
    height: 130vh;
  }
}

@media (max-width: 1100px) {
  .img_wrapper {
    height: 100vh;
  }
}
@media (max-width: 900px) {
  .img_wrapper {
    height: 90vh;
    object-fit: contain;
  }
}

@media (max-width: 768px) {
  .img_wrapper {
    height: 75vh;
    background-size: contain; /* For smaller screens, 'contain' adjusts the image to fit better */
  }
}

@media (max-width: 400px) {
  .img_wrapper {
    height: 50vh;
    background-size: contain; /* For smaller screens, 'contain' adjusts the image to fit better */
  }
}

/* .account_txt {
  color: grey !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  text-align: center !important;
}

.note {
  color: black !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  text-align: center !important;
  font-weight: 600 !important;
}

.done_logo {
  height: 100px;
  object-fit: contain;
}

.plus_icon {
  flex-shrink: 0;
  color: #071d5b;
  font-size: 30px;
}

.label {
  color: black !important;
  font-size: 20px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}

.link {
  color: #f15a24 !important;
  font-size: 17px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}

.link:hover {
  color: #13379b !important;
  text-decoration-line: underline !important;
  cursor: pointer !important;
}

@media (max-width: 900px) {
  .done_logo {
    height: 80px;
  }

  .plus_icon {
    font-size: 28px;
  }
}
@media (max-width: 600px) {
  .done_logo {
    height: 5vh;
    object-fit: contain;
  }

  .plus_icon {
    flex-shrink: 0;
    color: #071d5b;
    font-size: 25px;
  }
} */

.note_wrapper {
  border-left: 4px solid orange;
  padding: 20px 20px;
  margin: 20px 0px;
  background: linear-gradient(
    to right,
    rgba(255, 166, 0, 0.253),
    rgba(128, 128, 128, 0.048)
  ) !important;
}
.note_txt {
  color: black !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  text-align: center !important;
  font-weight: 600 !important;
}
.note_desc {
  color: rgb(114, 113, 113) !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  text-align: center !important;
  font-weight: 400 !important;
}

.account_txt {
  color: rgb(90, 90, 90) !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  text-align: center !important;
}


.note {
  color: black !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  text-align: center !important;
  font-weight: 600 !important;
}

.done_logo {
  height: 100px;
  object-fit: contain;
}

.plus_icon {
  flex-shrink: 0;
  color: #071d5b;
  font-size: 30px;
}

.label {
  color: black !important;
  font-size: 20px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}

.link {
  color: #f15a24 !important;
  font-size: 17px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}

.link:hover {
  color: #13379b !important;
  text-decoration-line: underline !important;
  cursor: pointer !important;
}

@media (max-width: 900px) {
  .done_logo {
    height: 80px;
  }

  .plus_icon {
    font-size: 28px;
  }
}
@media (max-width: 600px) {
  .done_logo {
    height: 5vh;
    object-fit: contain;
  }

  .plus_icon {
    flex-shrink: 0;
    color: #071d5b;
    font-size: 25px;
  }
}

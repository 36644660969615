.forth-heading {
  color: #071d5b !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: "DM Sans", sans-serif !important;
  /* font-size: 36px !important; */
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 46px !important;
  /* 127.778% */
}

.forth-sub-heading {
  color: #8d8d8d;
  font-feature-settings: "clig" off, "liga" off;
  width: 50vw !important;
  /* Body/Default */
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 15px;
  /* 166.667% */
}

.forth-text-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap !important;
}

.btn-container-forth {
  display: flex;
  padding: 15px 36px;
  align-items: flex-start;
  gap: 8px;
  background-color: #f15a24;
  color: white;
  border-radius: 40px;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 500px) {
  .forth-heading {
    font-size: 25px;
  }

  .forth-sub-heading {
    font-size: 15px;
    width: 100% !important;
    padding-bottom: 10px !important;
  }

  .btn-container-forth {
    padding: 8px 36px;
  }
  .forth-text-btn-container {
    align-items: flex-start;
    flex-direction: column !important;
  }
}

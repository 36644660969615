.cardMain {
  border-radius: 20px;
  background: var(
    --Blue-colour,
    linear-gradient(180deg, #071d5b 0%, #031444 100%)
  );
  flex-shrink: 0;
  width: 100% !important;
  transition: background 0.3s ease; 
  position: relative; 
  height: 100%;
}

.cardMain:hover {
  cursor: pointer;
}

.image-class-for-all {
  width: 25%;
  position: absolute; 
  top: 50%; 
  right: 0%; 
  transform: translateY(-50%) translateX(0); 
  transition: transform 0.3s ease; 
  opacity: 0.1;
  
}
/* .cardMain:hover .image-class-for-all{
  opacity: 0.9;
} */

.bottom-card-textHeading{
  color: white;
  transition: color 0.3s ease;
  font-weight: 900;
}
.bottom-card-textDisc{
  color: #cccaca;
  transition: color 0.3s ease;
}

.cardMain:hover .image-class-for-all {
  /* transform: translateY(-50%) translateX(100%);  */
  opacity: 0.5;
}
.cardMain:hover .bottom-card-textHeading {
  color: white;
  transition: color 0.3s ease;
}


@media (max-width: 500px) {
  .cardMain {
    height: 294px;
  }

  .image-class-for-all {
    width: 100px;
    height: 100px;
  }
}

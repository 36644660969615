.second_section_heading {
    color: #071D5B !important;
    text-align: center !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: "DM Sans", sans-serif !important;
    font-size: 36px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 38px;
    margin-bottom: 50px !important;
}


@media (max-width: 500px) {
    .second-section-heading {
        font-size: 25px !important;
    }
}
.search-container {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  /* margin: 20px; */
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.right-content {
  display: flex;
  align-items: center;
  margin-left: 15px;
  flex: 1;
}

.search-icon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 1.64px 2px 2.361px 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: gray;
}

.input-text {
  margin-left: 15px;
  color: #313131;
  /* text-align: center; */
  font-feature-settings: "clig" off, "liga" off;
  /* Text single/200/Regular */
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 18px; */
  border: none;
  outline: none;
  width: 100%;
  /* 100% */
}

.hero-btn {
  display: inline-flex;
  padding: 17px 35px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: #f15a24;
  outline: none;
  border: none;
  color: white;
  margin-right: 8px !important;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .input-text {
    font-size: 13px;
  }
  .search-container {
    height: 50px;
    width: 100%;
  }
  .hero-btn {
    padding: 13px 12px !important;
  }
}








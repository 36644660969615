.become_pro {
  color: #000 !important;
  font-family: var(--font_raleway) !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110% !important;
}

.done_pro {
  font-family: var(--font_raleway) !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 110% !important;
}

.check_icon {
  height: 30vh;
  width: 40vw;
  object-fit: contain;
}

.check_txt_1 {
  color: #333 !important;
  text-align: center !important;
  font-family: var(--font_raleway) !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 35px !important; /* 43.2px */
  letter-spacing: -0.96px !important;
}

.check_txt_2 {
  font-family: var(--font_raleway) !important;
  color: #333 !important;
  text-align: center !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  line-height: 110% !important;
}

/* Larger screens */
@media (min-width: 1200px) {
  .become_pro,
  .done_pro {
    font-size: 50px !important;
  }
  .check_txt_1 {
    font-size: 24px !important;
  }

  .check_txt_2 {
    font-size: 30px !important;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .become_pro,
  .done_pro {
    font-size: 40px !important;
  }
  .check_txt_1 {
    font-size: 22px !important;
  }
  .check_txt_2 {
    font-size: 28px !important;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .become_pro,
  .done_pro {
    font-size: 30px !important;
  }
  .check_txt_1 {
    font-size: 20px !important;
  }
  .check_txt_2 {
    font-size: 26px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .become_pro,
  .done_pro {
    font-size: 25px !important;
  }
  .check_txt_1 {
    font-size: 18px !important;
  }
  .check_txt_2 {
    font-size: 24px !important;
  }
}

.handleBtn {
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  background-color: #f15a24;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --active-sidebar-width: 220px;
  --inactive-sidebar-width: 220px;
  --transition-ref: all 0.5s ease-in-out;
  --transition-transform: width 0.5s ease-in-out;
  --mainColor: #13209f;
  --left-md-down-screen: -275px;
  --md-down-sidebar-width: 300px;
  --font-family-montserrat: "Montserrat", sans-serif;
}

.sidebar {
  position: fixed;
  display: block;
  z-index: 1001;
  width: var(--inactive-sidebar-width);
  height: 100vh;
  transition-property: width, border-right-color;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  /* transition: var(--transition-transform); */
  overflow: hidden;
  /* background-color: #13209f; */
  /* background-color: #ebebee; */
  /* border-right-width: 3px; */
  /* border-right-style: solid; */
  border-radius: "0px 20px 20px 0px";
  /* background-image: url('../../assets/clock.png'); */
  background-position: top center;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  /* border-right: 2px solid var(--mainColor); */
  /* box-shadow: 4px 0px 9px -2px rgba(78, 78, 78, 0.75); */
  /* box-shadow: 10px 0px 10px -5px rgba(0, 0, 0, 0.75); */
  box-shadow: -1px 0px 10px -5px rgba(0, 0, 0, 0.75);
}


.sidebar.active.hidden-side-bar {
  width: 0;
}

.sidebar.hidden-side-bar {
  width: 0;
}

.sidebar.active.hidden-side-bar ~ .show-hide-sidebar-toggle,
.sidebar.hidden-side-bar ~ .show-hide-sidebar-toggle {
  left: 0;
}

.sidebar.active.hidden-side-bar ~ .content-view,
.sidebar.hidden-side-bar ~ .content-view {
  left: 0;
  width: 100%;
}

.show-hide-sidebar-toggle {
  display: block;
  cursor: pointer;
  z-index: 1001;
  opacity: 1;
  position: absolute;
  width: 20px;
  height: 40px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  left: var(--inactive-sidebar-width);
  top: 50%;
  bottom: 50%;
  transition-property: left, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  /* transition: var(--transition-ref); */
}

.sidebar.active ~ .show-hide-sidebar-toggle {
  left: var(--active-sidebar-width);
}

.content-effect-logo-text {
  height: 57px;
  width: 120px;
  transition: display 0.25s ease-in-out;
  transition-delay: 0.2s;
  /* display: none; */
}

.sidebar.active ~ .content-effect-logo-text {
  /* display: block; */
}

.hideSideBar {
  width: 0 !important;
  left: 0 !important;
}

.topBarStyle {
  /* width: 100%; */
  height: 80px;
  /* opacity: 1; */
  transition: all 0.55s ease-in-out !important;
  /* overflow: hidden; */
  /* background-color: red; */
  /* box-shadow: 1px 6px 9px -2px rgba(0,0,0,0.44); */
  /* border-bottom: 1px solid black; */
  /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.75); */
  /* padding: 30px; */
}

.topBarStyle.hideTopBar {
  height: 0px !important;
  opacity: 0 !important;
}

.hide-show-hide-sidebar-toggle {
  left: -20px !important;
  opacity: 0;
}

.hideSideBar ~ .content-view {
  width: 100% !important;
  left: 0 !important;
}

.sidebar.active {
  height: 100vh;
  width: var(--active-sidebar-width);
  /* border-radius: 0px 20px 20px 0px; */
}

.sidebar.active ~ .content-view {
  left: var(--active-sidebar-width);
  width: calc(100% - var(--active-sidebar-width));
}

.content-view {
  position: absolute;
  left: var(--inactive-sidebar-width);
  height: 100%;
  width: calc(100% - var(--inactive-sidebar-width));
  transition: var(--transition-ref);
}

body {
  /* background-color: #F9FAFB; */
}

.appbar {
  box-shadow: 2px 2px 6px #00000054;
}

.sidebar.active ~ .content-effect {
  transition: var(--transition-ref);
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  /* outline: var(--mainColor) solid 1px !important; */
  /* border-style:1px solid var(--main-color); */
}

.transform-text-none {
  text-transform: none !important;
}

.btnStyleNone {
  background-color: transparent;
  border-style: none;
}

.btnStyleNone:hover {
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;
}

.toggleSidebarButton {
  display: none;
}

@media only screen and (max-width: 850px) {
  .sidebar {
    width: 0% !important;
    z-index: 1001;
    border-style: none;
    background-color: white;
    transition: var(--transition-ref);
    left: var(--left-md-down-screen);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .show-hide-sidebar-toggle {
    display: none !important;
  }

  .content-view {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fafafa;
    /* transition: left 0.5s ease-in-out; */
  }

  .sidebar.active ~ .content-view {
    left: 0;
    width: 100%;
    z-index: 100;
  }

  .sidebar.active {
    width: 100% !important;
    left: 0;
    /* background-color: #5568F0; */
    border-radius: 0px;
  }

  .mdDownsideBarMain {
    background-color: #00000092;
    height: 100vh;
    z-index: 1001;
  }

  .toggleSidebarButton {
    display: block;
  }
}

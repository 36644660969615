.card-container {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensure content stays within bounds */
}

/* .card-container:hover {
  background-color: rgba(241, 90, 36, 0.9);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
} */

/* .card-container:hover .card-heading-service,
.card-container:hover .service-sub-heading {
  color: white;
  opacity: 0.8;
  transition: color 0.3s ease;
} */

/* .card-container:hover .img-size {
  transform: scale(1.1);
} */

.img-size {
  /* width: 100%; */
  height: 100px;
  flex-shrink: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* transition: transform 0.3s ease; */
  object-fit: contain;
}

.card-heading-service {
  font-size: 16px;
  font-weight: 700;
  color: #071D5B;
  transition: color 0.3s ease;
  text-align: center;
}

.service-sub-heading {
  color: rgba(7, 29, 91, 0.3);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.25%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  transition: color 0.3s ease;
  text-align: center;
}

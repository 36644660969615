.become_pro {
  color: #000 !important;
  text-align: center !important;
  font-family: "Raleway" !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110% !important;
  letter-spacing: -2px !important;

}

.done_pro {
}

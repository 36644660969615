.logo {
  width: 96px;
  height: 96px;
}
.html {
  font-size: 62.5%;
}

.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}

.container {
  max-width: 1200px;
  width: 90%;
  margin: auto;
}

.navbar {
  position: fixed;
  width: 100%;
  color: #000;
  opacity: 0.85;
  z-index: 100;
  padding: 10px;
}

.navbar-container {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
}

.menu-items {
  order: 2;
  display: flex;
  align-items: center;
  /* background-color: #fff; */
}

.logo {
  font-size: 1.5rem;
}

.navbar {
  align-items: center;
  justify-content: center;
}
.navbtn {
  display: flex;
  flex-direction: row;
}
.narbar-ul-box {
  display: flex;
  background-color: rgb(236, 232, 232, 0.4);
  border-radius: 20px;
  padding: 15px;
}

.menu-items li {
  list-style: none;
  margin-left: 1.5rem;
  font-size: 14px;
  color: white;
  /* background-color: #aaa; */
}

/* .menu-items li:hover{
  list-style: none;
  margin-left: 1.5rem;
  font-size: 1.3rem;
  border-bottom: 1px solid whitesmoke;
  border-bottom-width: 2px;
} */

.navbar a {
  color: #444;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.navbar a:hover {
  color: #117964;
}

@media (max-width: 950px) {
  .navbar {
    opacity: 0.95;
  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }
  .navbtn {
    flex-direction: column;
  }
  .narbar-ul-box {
    display: block;
    border-radius: 20px;
    background-color: #fff;
    padding: 15px;
  }
  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #333;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 100px;
    background: white;
    height: 100vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
    overflow: scroll;
  }

  .navbar .menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: black;
  }

  .logo {
    position: absolute;
    top: -10px;
    right: 15px;
    /* font-size: 2.5rem; */
  }

  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}

.navbar-container input[type="checkbox"]:checked ~ .menu-items > li {
  color: black;
}

.sethampburger {
  justify-content: space-around !important;
}
.cart {
  display: none !important;
}
@media only screen and (max-width: 900px) {
  /* .navbar-container input[type="checkbox"]:checked~.logo {
      display: none;

  }

  .navbar-container {
      height: 28px;
  } */

  /* .logo{
    font-size: 18px;
  } */

  .sethampburger {
    justify-content: flex-start !important;
    align-items: center !important;
    padding: 10px;
  }

  .cart {
    display: block !important;
  }

  .hamBurger {
    display: block !important;
  }
}

.input {
  outline: none;
  border: 1px solid #f15a24;
  border-radius: 4px;
  padding: 18px;
  font-size: 16px;
  margin-top: 10px;
  padding-left: 50px;
}

.input::placeholder {
  color: #aaaaaa;
}

.label-text {
  color: #737373 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 30px !important;
}

.css-o4y5lm-MuiPaper-root-MuiAccordion-root:first-of-type {
  border-radius: 10px !important;
}

/* CustomMenu.css */

.custom-menu {
  position: relative;
  display: inline-block;
}

.menu-button {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  outline: none;
}

.menu-items {
  position: absolute;
  top: 55px;
  right: 15px;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #e6e4e4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.menu-item {
  display: block;
  width: 100%;
  padding: 11px 43px;
  color: #333;
  font-size: 16px;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: var(--font-family-montserrat) !important;
  border-radius: 4px;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

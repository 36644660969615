.slider-card-container {
  /* width: 95%; */
  /* height: 344px; */
  /* flex-shrink: 0; */
  border-radius: 24px;
  border: 1px solid #d4d2e3;
  background: #fff;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* margin-right: 10px; */
}

.star_icon {
  font-size: 30px !important;
}

.card-discription {
  color: #4b4b4b !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Body/Default */
  font-family: "DM Sans", sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  /* width: 323px; */
  margin-top: 20px !important;
  /* 166.667% */
}

.card-discription-head {
  color: #071d5b !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Text single/300/Bold */
  font-family: "DM Sans", sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  /* 100% */
  margin-top: 20px !important;
}

.card-discription-sub-head {
  color: #8d8d8d !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  /* 112.5% */
  margin-top: 20px !important;
}

@media only screen and (max-width: 900px) {
  .star_icon {
    font-size: 25px !important;
  }
  .card-discription {
    font-size: 16px !important;
  }
  .card-discription-head {
    font-size: 18px !important; 
  }
  .card-discription-sub-head {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .star_icon {
    font-size: 20px !important;
  }
  .card-discription {
    font-size: 14px !important;
  }
  .card-discription-head {
    font-size: 12px !important;
  }
  .card-discription-sub-head {
    font-size: 14px !important;
  }
}

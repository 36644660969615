.main_heading {
  font-family: "Raleway" !important;
  color: #333 !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: 41px !important;
}

.total {
  color: #333 !important;
  font-family: "Raleway" !important;
  font-size: 30px !important;
  font-weight: 500 !important;
  line-height: 41px !important;
}

/* Larger screens */
@media (min-width: 1200px) {
  .main_heading,
  .total {
    font-size: 30px !important;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .main_heading,
  .total {
    font-size: 28px !important;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .main_heading,
  .total {
    font-size: 26px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .main_heading,
  .total {
    font-size: 24px !important;
  }
}

.footer-container {
  background: white;
  border-top: 1px solid lightgray;
  flex-shrink: 0;
}

.footer-logo {
  width: 134px;
  height: 63px;
  flex-shrink: 0;
  /* margin-top: 80px; */
}

.footer-discription {
  width: 492px;
  color: #6d6d6d;
  text-align: center;

  /* Body/Default */
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 30px;
  /* 166.667% */
}

.footer-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.last-text {
  color: #071d5b;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 214.286% */
}

.policy-text {
  color: #071d5b;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 214.286% */
}

.footer_logo_images_container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 10px 0px;
}


.last-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10em; */
  /* padding: 0px 32px !important; */
}

.foot_app_store_styl {
  width: 87px;
  height: 30px;
  object-fit: contain;
}
.foot_google_store_styl {
  width: 88px;
  height: 35px;
  object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .last-container {
    display: block;
    justify-content: center;
    align-items: center;
    /* margin-top: 10em; */
  }

  .foot_app_store_styl {
    width: 87px;
    height: 30px;
    object-fit: contain;
  }
  .foot_google_store_styl {
    width: 88px;
    height: 35px;
    object-fit: contain;
  }
}

.description {
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 25.6px */
  color: black !important;
}

.first_txt {
  color: #000 !important;
  font-family: "Raleway" !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110% !important; /* 75.583px */
  letter-spacing: -2.748px !important;
}

.lst_txt {
  font-family: "Raleway" !important;
  color: #f15a24 !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 110% !important;
  letter-spacing: -2.748px !important;
}

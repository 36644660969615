.description {
  font-family: var(--font_raleway) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 25.6px */
  color: black !important;
}

.first_txt {
  color: #000 !important;
  font-family: var(--font_raleway) !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110% !important; /* 75.583px */
  letter-spacing: -2.748px !important;
}

.lst_txt {
  font-family: var(--font_raleway) !important;
  color: #f15a24 !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 110% !important;
  letter-spacing: -2.748px !important;
}

.otp_label {
  font-family: var(--font_raleway) !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 160% !important; /* 25.6px */
  color: black !important;
  white-space: nowrap !important;
}

/* Larger screens */
@media (min-width: 1200px) {
  .otp_label {
    font-size: 18px !important;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .otp_label {
    font-size: 16px !important;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .otp_label {
    font-size: 15px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .first_txt,.lst_txt {
    font-size: 40px !important;
  }
  .description{
    font-size: 13px !important;
  }
  .otp_label {
    font-size: 14px !important;
  }
}

.App {
}

.chat-window {
  width: 100%;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  overflow: auto !important;
  /* flex: 1 !important; */
  height: 66vh;
  padding: 10px 30px !important;
  /* position: relative; */
  background-color: white;
}

/* .chat-window:: */

.chat-window::-webkit-scrollbar {
  width: 0.2em;
  border-radius: 50px;
  padding-right: 20px;
}

.chat-window::-webkit-scrollbar-thumb {
  background-color: #ef873a;
  width: 0.1px;
  border-radius: 50px;
  padding-right: 20px;
}

.chat-window::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 50px;
  /* padding-right: 20px; */
}

.message {
  margin-bottom: 20px;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 80% !important;
  font-family: "Poppins", sans-serif;
  background-color: #cb5c0a;
}

.message.right {
  background-color: #f1ebeb;
  max-width: fit-content !important;
  align-self: flex-end !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.message.left {
  background-color: #2186eb;
  align-self: flex-start;
  max-width: fit-content;
  color: white;
  /* margin-top: 30px; */
}

/* ============================= */
.input-container {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  /* margin-top: 10px; */
  gap: 10px;
  /* padding: "10px 50px", */
  height: 10%;
}

.input-field {
  display: flex;
  /* width: 406px; */
  width: 88% !important;
  padding: 5px 20px;
  align-items: center;
  gap: 22px;
  border-radius: 10px;
  border: 1px solid #e8e0e0;
  background: #f5f0f0 !important;
  border: 1px solid #e8e0e0;
  outline: none;
  height: 50px;
  font-size: 16px;
  font-weight: medium;
  font-family: '"Poppins", sans-serif';
}

.submit-btn {
  width: 47px !important;
  height: 47px !important;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(
    --Button,
    linear-gradient(0deg, #ef873a 0%, #cb5c0a 100%)
  ) !important;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ===================== userList */

.user-list {
  /* width: 200px; */
  /* border-right: 1px solid #ccc; */
}

.user {
  /* padding: 10px; */
  cursor: pointer;
  color: black;
}

.user.selected {
  background: var(
    --Blue-colour,
    linear-gradient(180deg, #071d5b 0%, #031444 100%)
  );
  color: white;
}

.img_wrapper {
  width: 100%;
  height: 50vh;
  background-color: #115061;
}

.main_title {
  color: white !important;
  font-weight: 700 !important;
  font-size: 70px !important;
  line-height: "normal" !important;
}

.main_heading {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: "normal" !important;
}

.sub_heading {
  font-size: 16px !important;
  font-weight: 300 !important;
  font-family: var(--font-family) !important;
  line-height: normal !important;
  font-style: italic;
  text-decoration-line: underline;
  padding-top: 5px !important;
  padding-bottom: 3px !important;
}

.sub_title {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: var(--font-family) !important;
  text-decoration-line: underline !important;
}

.defination_line {
  font-size: 17px !important;
  font-weight: 500 !important;
  font-family: var(--font-family) !important;
  line-height: 22px !important;
}

.options {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: var(--font-family) !important;
  line-height: 20px !important;
}

.order_lst {
  display: flex !important;
  flex-direction: column !important;
  gap: 7 !important;
}

.custom_ol {
  list-style-type: square !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 7 !important;
  padding-left: 30px !important;
}

.sub_data_list {
  list-style-type: lower-alpha !important;
}

.nested_order_lst {
  padding-left: 30px !important;
}

/* .................... */
.bold_txt {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: var(--font-family) !important;
  line-height: 20px !important;
}
.light_txt {
  font-size: 14px !important;
  /* font-weight: 600 !important; */
  font-family: var(--font-family) !important;
  line-height: 20px !important;
}

/*  ========================= First Step ========================= */
.When-should {
  color: #000;
  font-family: sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top: 48px;
  /* 75% */
}

.availible-date {
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.Timing-constraints {
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.Timing-constraints-container {
  /* width: 996px; */
  height: 120px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #f15a24;
  margin-top: 20px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  padding: 20px;
}

.time_contraints_inp::placeholder {
  font-size: 14px;
}

/* .next-btn {
  display: inline-flex;
  padding: 10px 57px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #f15a24;
  color: white;
  margin-top: 40px;
  width: 150px;
  cursor: pointer;
} */

.next-btn {
  display: inline-flex;
  padding: 10px 57px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #f15a24;
  color: white;
  margin-top: 40px;
  margin-left: 10px !important;
  width: 150px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.next_btn_hov:hover {
  transform: scale(1.05);
  background: #d14e20;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.service-sub-name {
  color: #000 !important;
  font-size: 1.775em !important;
  font-weight: 500 !important;
  line-height: 1.27 !important;
}

.stepperClass {
  width: 100%;
  /* margin-left: -120px; */
  margin-top: 50px;
  /* background-color: red !important; */
}
.overflow_stepper::-webkit-scrollbar {
  display: none !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
@media only screen and (max-width: 640px) {
  .stepperClass {
    width: 100% !important;
    /* margin-left: -14px !important; */
    margin-top: 4px !important;
  }
}
/*  ========================= First Step ========================= */
/*  ========================= Second Step ========================= */

.upload-image-container {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  /* background-color: rgb(254 238 233); */
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px dashed grey;
}

.add-photo-title {
  color: #d14e20;
  text-align: center;
  font-family: sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 75% */
}

.next-btn-outline {
  display: inline-flex;
  padding: 10px 57px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #f15a24;
  color: #f15a24;
  margin-top: 40px;
  width: 150px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.next-btn-outline:hover {
  transform: scale(1.05);
  color: "white" !important;
  box-shadow: 8px 6px 16px 2px rgba(0, 0, 0, 0.31);
}
.next-btn-hov:hover {
  transform: scale(1.05);
  background: #d14e20;
  box-shadow: 8px 6px 16px 2px rgba(0, 0, 0, 0.31);
}
/*  ========================= Second Step ========================= */
/*  ========================= Last Step ========================= */
.heading-name-text {
  color: #000 !important;
  font-family: sans-serif !important;
  font-size: 38px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 30px !important;
}

.heading-side-text {
  color: #000;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 125% */
}

.Service-Name-head {
  color: #000 !important;
  font-family: sans-serif !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 30px !important;
}

.Service-Name-list {
  color: #000 !important;
  font-family: sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}
.service_shifts_txt {
  color: grey !important;
  font-family: sans-serif !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}

.need-to-cancel-caontainer {
  height: 100px;
  flex-shrink: 0;
  background-color: rgb(254 238 233);
  border-radius: 10px;
  margin-top: 20px;
}

.service-list {
  color: #000 !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 30px !important;
}

.edit_job_btn {
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.edit_job_btn:hover {
  transform: scale(1.03);
  background: #d14e20;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.cancel_btn {
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.cancel_btn:hover {
  transform: scale(1.03);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

/*  ========================= Last Step ========================= */

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:ital,wght@0,100..900;1,100..900&display=swap");

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url("./fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  /* font-family: 'Rajdhani', sans-serif; */

  /* url("path/to/font.woff") format("woff"); */
  /* Add additional formats if necessary */
  /* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap') */
}
/* @font-face {
  font-family: 'AftaSansThin-Regular';
  src: url('./fonts/Raleway-VariableFont_wght.ttf') format('opentype');
} */

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.number_family {
  font-family: sans-serif !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

:root {
  /* --primaryColour: #fa8232; */
  --primaryColour: #f15a24;
  --font_rokkitt: "Rokkitt", serif;
  --font_raleway: "Raleway", serif;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .css-5xe99f-MuiLinearProgress-bar1{
  background-color: #F39473 !important;
} */

html {
  font-size: 0.8rem;
}

.globleGradient {
  background-image: linear-gradient(45deg, #3d75c8, #df6011);
  -webkit-background-clip: text;
  color: transparent;
}

.globleGradientBlue {
  /* border-radius: 0px 0px 20px 20px; */
  background: var(
    --Blue-colour,
    linear-gradient(180deg, #071d5b 0%, #031444 100%)
  );
  box-shadow: 0px 22px 33.6px 0px rgba(0, 0, 0, 0.22);
}

.stripeInput {
  background-color: white;
  padding: 14px;
  border: 1px solid gray;
  border-radius: 5px;
}

.globleVerticalLine {
  width: 1px;
  /* Line ki width ko adjust karein */
  height: 19px;
  /* Container ki height tak extend ho */
  background-color: #9f9f9f;
  /* Line ki color */
  margin: 0 10px;
  /* Line ke left-right margin ko adjust karein */
}

.custom-selected {
  background: var(--Orange, linear-gradient(180deg, #f15a24 0%, #c53f10 100%));
  color: white !important;
  border: none !important;
  border-radius: 50%;
}

.custom-recent-selected {
  border: 2px solid white !important;
  border-radius: 50%;
}

/* Custom styles for the arrows */
.rdp-chevron {
  fill: #df6011 !important; /* Theme orange color */
}

.rdp-nav_button:hover {
  background-color: #ffd580 !important; /* Lighter orange hover effect */
  color: #df6011 !important;
}

/* Custom styles for today's date */
.rdp-today {
  color: #df6011 !important; /* Light orange color */
}
.rdp-today.custom-selected {
  color: white !important; /* Light orange color */
}

.mainHeading {
  font-size: 30px !important;
  font-weight: 700 !important;
}
.subHeading {
  font-size: 22px !important;
  font-weight: 600 !important;
}
.mainPara {
  font-size: 12px !important;
  font-weight: 400 !important;
}
.secondSubHeading {
  font-size: 24px !important;
  font-weight: 500 !important;
}
.subpara {
  font-size: 20px !important;
  font-weight: 500 !important;
}

@media only screen and (max-width: 640px) {
  html {
    font-size: 1rem;
  }
  .mainHeading {
    font-size: 22px !important;
    font-weight: 500 !important;
  }
  .subHeading {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .mainPara {
    font-size: 8px !important;
    font-weight: 300 !important;
  }
  .secondSubHeading {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .subpara {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  /* ._3Tv81{
    width: 81% !important;
    margin-left: 119px !important;
    margin-top: 4px !important;
  } */
}
@media only screen and (max-width: 640px) {
  .mainHeading {
    font-size: 19px !important;
    font-weight: 500 !important;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 1rem;
  }
  .mainHeading {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .subHeading {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .mainPara {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .secondSubHeading {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
  .subpara {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}

@media only screen and (min-width: 1024px) {
  .mainHeading {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .subHeading {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .mainPara {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .secondSubHeading {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  .subpara {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
}

@media only screen and (min-width: 1280px) {
}

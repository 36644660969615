.img_wrapper {
  width: 100%;
  height: 76vh;
  background: url("../../assets/service_banner.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.second_section_heading {
  color: #071d5b !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: "DM Sans", sans-serif !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 38px;
  margin-bottom: 50px !important;
}

@media (max-width: 500px) {
  .second_section_heading {
    font-size: 25px !important;
  }
}

.label {
  color: #000 !important;
  font-family: "Raleway" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

/* Larger screens */
@media (min-width: 1200px) {
  .label {
    font-size: 20px !important;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .label {
    font-size: 18px !important;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .label {
    font-size: 17px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .label {
    font-size: 15px !important;
  }
}

.primary-btn {
  background-color: var(--primaryColour);
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 7px;
  border: none;
  outline: none;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.primary-btn:hover {
  cursor: pointer;
  transform: scale(1.03);
}

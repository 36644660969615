.inp_label {
  font-size: 14px !important;
  color: #565774 !important;
  font-family: "Poppins", sans-serif !important;
}

.inp {
  outline: none;
  border: none;
  border-bottom: 2px solid #3549de;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  padding: 3px 5px;
}

.email_location {
  font-size: 14px !important;
  color: #3549de !important;
}
.title {
  font-size: 17px !important;
  font-weight: 700 !important;
  color: #3549de !important;
}

.banner_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img_wrapper {
  width: 100%;
  height: 70vh;
  background: url("../../assets/about_banner.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.main_heading {
  color: white !important;
  font-weight: 900 !important;
  line-height: 45px !important;
}

.intro_title {
  color: #071d5b !important;
  font-weight: 600 !important;
  line-height: 60px !important;
  text-align: center !important;
}

.intro_description {
  color: #95979c !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  text-align: center !important;
}

.best_home_ment_txt {
  color: #f15a24 !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.done_right_away_txt {
  color: #071d5b !important;
  font-weight: 600 !important;
  line-height: 57px !important;
}

.qualified_worker_txt {
  color: white !important;
  font-weight: 600 !important;
  line-height: 57px !important;
}

.qualified_worker_wrapper {
  position: absolute !important;
  bottom: 20% !important;
  left: 7% !important;
  width: 50% !important;
}

.done_right_away_description {
  color: #95979c !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.counting {
  color: black !important;
  font-weight: 500 !important;
  line-height: 28px !important;
}

.certified_team {
  color: white !important;
  font-weight: 400 !important;
  line-height: 31px !important;
  width: 70% !important;
}

.qualified_worker {
  background: url("../../assets/qualified_wrokers.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 20px !important;
  flex-shrink: 0 !important;
  height: 350px !important;
  width: 100% !important;
  position: relative !important;
}

.circular_lines {
  background: url("../../assets/count_service_img.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.service_man {
  height: 100% !important;
  width: 100% !important;
  /* object-fit: contain !important; */
}

.laptop_mobile_styl {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain !important;
}

.service_laptop {
  height: 76% !important;
  width: 76% !important;
  object-fit: contain !important;
  position: absolute !important;
  left: -25% !important;
}

.app_store_styl {
  width: 145px;
  height: 50px;
  object-fit: contain;
}
.google_store_styl {
  width: 145px;
  height: 60px;
  object-fit: contain;
}

@media screen and (max-width: 600px) {
  .intro_title {
    line-height: 38px !important;
  }
  .app_store_styl {
    width: 110px;
    height: 50px;
    object-fit: contain;
  }
  .google_store_styl {
    width: 110px;
    height: 60px;
    object-fit: contain;
  }
}

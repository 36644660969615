/* ================================================    First Card   ================================================  */

.service-name {
  color: #000 !important;
  font-size: 2.25em !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.job-review {
  color: #4a4a4a !important;
  font-family: sans-serif !important;
  font-size: 0.857em !important;
  line-height: 1 !important;
}

.review-btn-container {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  background: #0f2563 !important;
}

.review-percent-text {
  color: #fff !important;
  text-align: center !important;
  font-family: sans-serif !important;
  font-size: 1.1em !important;
  font-weight: 500 !important;
  line-height: 1 !important;
}

.service-sub-name {
  color: #000 !important;
  font-size: 1.775em !important;
  font-weight: 500 !important;
  line-height: 1.27 !important;
}
.reviews_heading_txt {
  color: #000 !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.job-details {
  color: #2b2b2b !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 1.75 !important;
}

.category-text {
  color: #2b2b2b !important;
  font-family: sans-serif !important;
  font-size: 0.875em !important;
}
.save_25 {
  color: white !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.on_every_done_txt {
  color: white !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.for_10_month {
  color: #f15a24 !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.category-text_link {
  color: #f15a24 !important;
  font-family: sans-serif !important;
  font-size: 0.875em !important;
  cursor: pointer !important;
  font-weight: 400 !important   ;
  flex-shrink: 0 !important;
}

.how-its-works {
  color: #000 !important;
  font-family: sans-serif !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
}

.how-its-works-discription {
  color: #2b2b2b !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.7 !important;
}

.certified-details {
  color: #2b2b2b !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.7 !important;
}
.review_wrapper {
  box-shadow: 0 3px 10px 0 rgba(116, 116, 116, 0.25);
  border-radius: 10px !important;
  padding: 25px !important;
  border: 1px solid #dddddd;
}

.review_vendor_name {
  color: #4a4a4a !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  flex-shrink: 0 !important;
}

.review_vendor_certification {
  color: #f15a24 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  flex-shrink: 0 !important;
}

.review_vendor_like_percant {
  color: white !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  flex-shrink: 0 !important;
}

.review_vendor_labels {
  color: rgb(68, 68, 68) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  flex-shrink: 0 !important;
  background-color: #f5f5f5 !important;
  border-radius: 8px !important;
  padding: 4px 15px !important;
}

.review_vendor_feedback_title {
  color: #4a4a4a !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  flex-shrink: 0 !important;
  text-align: center !important;
}

.review_vendor_recent_review_txt {
  color: #f15a24 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  flex-shrink: 0 !important;
  text-align: center !important;
}
.review_vendor_recent_review_description {
  color: #302f2f !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.review_vendor_rate_verfied_txt {
  color: #302f2f !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.member_ship_go_back_btn {
  background-color: transparent !important;
  border: 1px solid white !important;
  color: white !important;
  transition: background-color 0.3s ease-in !important;
  width: 100% !important;
  height: auto !important;
  border-radius: 40px !important;
}

.member_ship_go_back_btn:hover {
  background-color: white !important;
  color: #d14e20 !important;
  border: 1px solid white !important;
  font-weight: 600 !important;
}

.member_ship_agree_continue_btn {
  background: var(
    --Orange,
    linear-gradient(180deg, #f15a24 0%, #c53f10 100%)
  ) !important;
  color: white !important;
  transition: background-color 0.3s ease-in !important;
  width: 100% !important;
  height: auto !important;
  border-radius: 40px !important;
}

@media (max-width: 880px) {
  .service-name {
    font-size: 35px !important;
  }

  .job-review {
    font-size: 18px !important;
  }

  .service-sub-name {
    font-size: 30px !important;
  }

  .job-details {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .category-text {
    font-size: 13px !important;
  }

  .how-its-works {
    font-size: 20px !important;
  }

  .how-its-works-discription {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .certified-details {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

/* ================================================    First Card   ================================================  */

/* ================================================    Second Card   ================================================  */

.top-border {
  border-radius: 10px 10px 0px 0px;
  background: #f15a24;
  height: 20px;
}

.questions-about {
  color: #000 !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  flex-shrink: 0 !important;
}

.learn-more {
  color: #f15a24 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  flex-shrink: 0 !important;
  cursor: pointer !important;
}

.burners {
  height: 170px;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgb(254 238 233);
}

.burners-text {
  color: #000;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  z-index: 2px;
  /* 125% */
}

.burners-price {
  color: #000;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  text-align: end;
  /* 125% */
}

.discount-container {
  /* width: 573px; */
  height: 293px;
  flex-shrink: 0;
  border-radius: 20px;
  background: linear-gradient(180deg, #071d5b 0%, #5a70af 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
}

.additional-burner-one {
  color: #fff;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  width: 154px;
  /* 125% */
}

.additional-burner {
  color: #fff;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 125% */
}

.burner-discount {
  color: #fff;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-decoration-line: line-through;
  opacity: 0.4;
}

.burner-amount {
  color: #f15a24;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.more-benefits {
  width: 393px;
  color: #fff;
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 166.667% */
}

.different-address {
  color: #000;
  font-family: sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 187.5% */
  text-decoration-line: underline;
}

.get-start-btn {
  width: 100% !important;
  flex-shrink: 0;
  border-radius: 40px;
  background: #f15a24;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 30px !important;
  align-self: center !important;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.get-start-btn:hover {
  transform: scale(1.05);
  background: #d14e20;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.botto-border {
  display: flex !important;
  background: rgb(254 238 233) !important;
  padding: 10px 20px !important;
  color: black !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}

.bottom-content {
  height: 100px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.bottom-content-two {
  height: 224px;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgb(254 238 233);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.choose_rate_txt {
  font-size: 15px !important;
  color: black !important;
  font-weight: 700 !important;
}

.bottom-content-text {
  color: #000;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  justify-content: center;
  align-items: center;
}

.member_txt {
  color: #f15a24 !important;
  font-family: sans-serif !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  /* line-height: normal !important; */
  text-transform: capitalize !important;
}

@media (max-width: 880px) {
  .questions-about {
    font-size: 17px !important;
  }

  .learn-more {
    font-size: 17px;
  }

  .burners-text {
    font-size: 18px;
  }

  .burners-price {
    font-size: 18px;
  }

  .additional-burner-one {
    font-size: 18px;
  }

  .additional-burner {
    font-size: 18px;
  }

  .more-benefits {
    width: 300px;
    font-size: 14px;
  }

  .learn-more {
    font-size: 14px;
  }
}

/* ================================================    Second Card   ================================================  */
/* ================================================    Discount Card   ================================================  */

.discount-main {
  flex-shrink: 0;
  /* background: #0f2563; */
  background: linear-gradient(180deg, #273350, #2f4f94);
  border-radius: 10px;
}

.discount-border {
  flex-shrink: 0;
  border-radius: 10px;
  border: 3px solid #f15a24;
  justify-content: center;
  align-items: center;
  margin: 5px 3px !important;
  background: #0f2563;
  width: 100% !important;
}

.perc-off {
  color: #fff;
  font-family: sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.joining_easy_txt {
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  text-align: center !important;
}

.joining_easy_description {
  color: #fff !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-align: center !important;
}

.perc-off-inner {
  color: #fff;
  font-family: sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.list_name {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

/* ================================================    Discount Card   ================================================  */

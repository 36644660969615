Style the overall home section .home-section {
  position: relative;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  z-index: 2;
}

/*  */
.hero-heading {
  color: #fff;
  font-size: 96px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  animation: type 3s steps(90) 0.4s 1 normal both, cursor 1s step-end infinite;
  position: relative;
}

@keyframes type {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}

/*  */

.hero-sub-heading {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-family: "DM Sans", sans-serif;
  overflow: hidden;
  white-space: nowrap;
  animation: type 3s steps(90) 0.4s 1 normal both, cursor 1s step-end infinite;
  position: relative;
}

.search-bar {
  width: 300px;

  padding: 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bottom-container {
  text-align: center;
  margin-top: 70px;
}

.botton-heading {
  color: #071d5b !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: "DM Sans", sans-serif !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 38px !important;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap !important;
  gap: 40px;
  margin-top: 30px;
  overflow-x: scroll;
  width: 100%;
}

.logo-container::-webkit-scrollbar {
  display: none;
}

.logo-size {
  width: 103.018px;
  height: 34.339px;
  flex-shrink: 0;
  object-fit: contain;
}

.bottom-discription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto !important;
}

.bottom-discription {
  color: #031444;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media (max-width: 700px) {
  .logo-size {
    width: 86.018px;
    height: 30.339px;
    flex-shrink: 0;
  }
}

@media (max-width: 500px) {

  .botton-heading {
    font-size: 25px !important;
    line-height: normal !important;
  }

  .logo-size {
    width: 51.018px;
    height: 23.339px;
    flex-shrink: 0;
  }

  .bottom-discription {
    font-size: 14px;
    width: 90% !important;
  }

  .logo-container {
    gap: 22px;
  }
}

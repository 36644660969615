.jobs_images_scrollbar_wrapper::-webkit-scrollbar {
  display: none;
}

.jobs_images_scrollbar_wrapper {
  overflow: auto;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:focus {
  border-color: grey;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:focus-visible {
  border-color: grey;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root :focus-within {
  border-color: grey;
}

.user_dashboard_member_txt {
  color: #f15a24 !important;
  font-family: sans-serif !important;
  font-size: 25px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.user_dashboard_discount_border {
  flex-shrink: 0;
  border-radius: 10px;
  border: 3px solid #f15a24;
  justify-content: center;
  align-items: center;
  margin: 5px 3px !important;
  background: #0f2563;
  width: 100% !important;
}

.user_dashboard_save_25 {
  color: white !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.user_dashboard_on_every_done_txt {
  color: white !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.user_dashboard_for_10_month {
  color: #f15a24 !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  flex-shrink: 0 !important;
}

.subscription_member {
  color: #000 !important;
  font-family: "Raleway" !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110% !important;
}

.subscription_ship {
  font-family: "Raleway" !important;
  font-size: 50px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 110% !important;
}

.member_ship_card_wrapper {
  height: 100%;
  border: 0.872px solid var(--Orange, #f15a24);
  border-radius: 17px;
  padding: 25px;
  gap: 20px;
  transition: color 0.6s ease, filter 0.6s ease, background 0.6s ease !important;
}

.member_ship_card_title {
  color: #333 !important;
  font-family: "Raleway" !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  transition: color 0.3s ease, filter 0.3s ease !important;
  min-height: 58px !important;
}

.member_ship_card_description {
  color: #333 !important;
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 35.756px !important; /* 223.474% */
  transition: color 0.3s ease, filter 0.3s ease !important;
}

.member_ship_card_icon {
  height: 100px;
  width: 100px;
  object-fit: contain;
  transition: filter 0.3s ease !important;
}

.member_ship_card_wrapper:hover .member_ship_card_icon {
  filter: brightness(0) invert(1); /* Change image to white */
}

.member_ship_card_wrapper:hover .member_ship_card_title,
.member_ship_card_wrapper:hover .member_ship_card_description {
  color: white !important; /* Change text to white */
}

.member_ship_card_wrapper:hover {
  background: var(
    --Orange,
    linear-gradient(180deg, #f15a24 0%, #c53f10 100%)
  ) !important;
}

/* Larger screens */
@media (min-width: 1200px) {
  .subscription_member,
  .subscription_ship {
    font-size: 50px !important;
  }
  .member_ship_card_icon {
    height: 100px;
    width: 100px;
    object-fit: contain;
  }

  .member_ship_card_title {
    font-size: 24px !important;
  }

  .member_ship_card_description {
    font-size: 16px !important;
  }
}

/* Medium screens */
@media (max-width: 1199px) and (min-width: 768px) {
  .subscription_member,
  .subscription_ship {
    font-size: 40px !important;
  }
  .member_ship_card_icon {
    height: 80px;
    width: 80px;
    object-fit: contain;
  }

  .member_ship_card_title {
    font-size: 22px !important;
  }
  .member_ship_card_description {
    font-size: 14px !important;
  }
}

/* Small screens */
@media (max-width: 767px) {
  .subscription_member,
  .subscription_ship {
    font-size: 30px !important;
  }
  .member_ship_card_icon {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
  .member_ship_card_title {
    font-size: 20px !important;
  }
  .member_ship_card_description {
    font-size: 12px !important;
  }
}

/* Extra small screens */
@media (max-width: 575px) {
  .subscription_member,
  .subscription_ship {
    font-size: 25px !important;
  }
  .member_ship_card_icon {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }
  .member_ship_card_title {
    font-size: 18px !important;
  }
  .member_ship_card_description {
    font-size: 12px !important;
    line-height: 22px !important;
  }
}
